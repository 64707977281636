

type Props = {};

const ExamRoutine = (props:Props) => {
    return (
        <div>This Is Exam Routine Page</div>
    );
};

export default ExamRoutine;