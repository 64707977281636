import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { alertError } from "../../components/common/alertUtils";
import HeadLine from "../../components/common/HeadLine";
import TableView from "../../components/common/TableView";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, Button, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeletoned from "../../components/common/Skeletoned";
import { api } from "../../routes/config";

type Props = {};

const AttendanceInfoPage = (props: Props) => {
  const { data } = useSelector((state: RootState) => state.login);
  const identificationId = data.item.identificationId;
  const instituteId = data.item.instituteId;
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [resultData, setResultData] = useState<any>({});

  const handleFromDateChange = (event: any) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: any) => {
    setToDate(event.target.value);
  };

  const handleSubmit = async () => {
    if (fromDate && toDate) {
      try {
        setIsLoading(true);
        const resultResponse = await fetch(
          `${api}/public/student/single/date-to-date/attendance?identificationId=${identificationId}&fromDate=${fromDate}&toDate=${toDate}&instituteId=${instituteId}`
        );
        const resultData = await resultResponse.json();

        console.log(resultData);

        if (resultData.item && resultData.item.length > 0) {
          setResultData(resultData);
        } else {
          setResultData({});
          alertError("Attendance Not Found");
        }
      } catch (error) {
        setResultData({});
        alertError("Error fetching result data");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const columns = [
    {
      id: "attendanceDate",
      label: "Attendance Date",
      showOnResponse: true,
    },
    {
      id: "status",
      label: "Status",
      showOnResponse: true,
    },
    {
      id: "attendanceType",
      label: "Type",
      showOnResponse: true,
    },
  ];

  return (
    <>
      <Paper elevation={0}>
        <HeadLine text={"Attendance Information"} />

        <Box sx={{ textAlign: "center", marginTop: 10, marginBottom: 10 }}>
          <FormControl
            sx={{ minWidth: 200, marginRight: 10 }}
            variant="standard"
          >
            <TextField
              label="From Date"
              type="date"
              variant="standard"
              value={fromDate}
              onChange={handleFromDateChange}
              sx={{ marginRight: 2 }}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <FormControl
            sx={{ minWidth: 200, marginRight: 10 }}
            variant="standard"
          >
            <TextField
              label="To Date"
              type="date"
              variant="standard"
              value={toDate}
              onChange={handleToDateChange}
              sx={{ marginRight: 2 }}
              InputLabelProps={{ shrink: true }}
            />
          </FormControl>
          <Button
            sx={{ marginTop: 2 }}
            variant="outlined"
            onClick={handleSubmit}
            startIcon={<SearchIcon />}
            color="success"
          >
            Search
          </Button>
        </Box>

        {isLoading ? (
          <Skeletoned count={15} animation="wave" />
        ) : resultData.item && resultData.item.length > 0 ? (
          <TableView
            columns={columns}
            dataSource={resultData.item}
            pagination={false}
          />
        ) : (
          ""
        )}
      </Paper>
    </>
  );
};

export default AttendanceInfoPage;
