import React from 'react';

type Props = {};

const ClassRoutine = (props:Props) => {
    return (
        <div>This Is Class Routine Page</div>
    );
};

export default ClassRoutine;